import axios, { type AxiosError, type AxiosInstance, type AxiosResponse, type RawAxiosRequestConfig } from 'axios';
import { useAuthStore } from '~/stores/api/AuthStore';
import { useUserStore } from '~/stores/api/UserStore';
import { useTransportOrganizerStore } from '~/stores/api/TransportOrganizerStore';
import type { HydraError, HydraResponse, JsonLdResponse } from '~/composables/useApiConsumer';
import { ClientError } from '~/api/error/ClientError';
import { ApiError } from '~/api/error/ApiError';

type CustomAxiosMethod = '$get' | '$post' | '$put' | '$patch' | '$delete';
const noRetrySymbol = Symbol();

declare module 'axios' {
  // noinspection JSUnusedGlobalSymbols
  interface InternalAxiosRequestConfig {
    [noRetrySymbol]?: boolean;
  }
}

export interface CustomAxiosInstance extends AxiosInstance {
  $get<T = object, D = object>(url: string, config?: RawAxiosRequestConfig<D>): Promise<T>;
  $post<T = object, D = object>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<T>;
  $put<T = object, D = object>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<T>;
  $patch<T = object, D = object>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<T>;
  $delete<T = object, D = object>(url: string, config?: RawAxiosRequestConfig<D>): Promise<T>;
}

export default defineNuxtPlugin(() => {
  const userStore = useUserStore();
  const transportOrganizerStore = useTransportOrganizerStore();

  const runtimeConfig = useRuntimeConfig();
  const baseURL = import.meta.server ? runtimeConfig.public.serverApiUrl : runtimeConfig.public.clientApiUrl;

  const client = axios.create({ baseURL }) as CustomAxiosInstance;

  for (const method of ['get', 'post', 'put', 'patch', 'delete']) {
    const key = ('$' + method) as CustomAxiosMethod;

    /* eslint-disable @typescript-eslint/no-explicit-any */
    client[key] = function (this: any, ...args: any[]) {
      return this[method](...args).then((res: AxiosResponse) => res && res.data);
    }.bind(client);
  }

  client.interceptors.request.use(function (config) {
    const authStore = useAuthStore();

    if (authStore.canBeAuthenticate() && !config.url?.startsWith('/security/token')) {
      config.headers['Authorization'] = `Bearer ${authStore.token}`;
    }

    if (config.method === 'patch') {
      config.headers['Content-Type'] = 'application/merge-patch+json';
    }

    if (!userStore.user) {
      return config;
    }

    if (config.method === 'get') {
      const url = new URL(config.url!, config.baseURL);

      if (!url.searchParams.has('transportOrganizer')) {
        url.searchParams.append('transportOrganizer', transportOrganizerStore.currentTransportOrganizer!.id.toString());
      }

      config.url = `${url.pathname}${url.search}`;
    }

    if ((config.method === 'post' || config.method === 'put') && Object.keys(config.data || []).length) {
      config.data.transportOrganizer = `/api/transport_organizers/${transportOrganizerStore.currentTransportOrganizer!.id}`;
    }

    return config;
  });

  client.interceptors.response.use(
    (response: AxiosResponse<JsonLdResponse, any>) => {
      const data = response.data;

      if (!data) {
        return response;
      }

      resolveIdFromJsonLdMetadata(data);

      return response;
    },
    async (axiosError: AxiosError<Error | HydraError>) => {
      const response = axiosError?.response;
      const data = response?.data;

      let error;

      if (data instanceof Error) {
        error = new ClientError(data.message || axiosError.message, axiosError.code);
      } else if (data && ('description' in data || 'hydra:description' in data)) {
        error = new ApiError(data['description'] || data['hydra:description'], data.violations);
      }

      if (response?.status === 401) {
        const config = { ...response.config };

        if (config.url?.startsWith('/security/token')) {
          throw error;
        }

        if (!config[noRetrySymbol]) {
          config[noRetrySymbol] = true;

          const authStore = useAuthStore();

          if (authStore.canBeRefresh()) {
            await authStore.refresh();

            config.headers['Authorization'] = `Bearer ${authStore.token}`;

            return client.request(config);
          } else {
            await authStore.logout();
          }
        }
      }

      throw error;
    }
  );

  /**
   * Extracts a numeric ID from the `@id` field of a JSON-LD response, if `id` does not exist.
   */
  function resolveIdFromJsonLdMetadata(data: JsonLdResponse) {
    if (data['@id'] && !data['id']) {
      const decomposedIdMetadataUri = data['@id'].split('/');
      let id = null;

      do {
        id = Number(decomposedIdMetadataUri.pop());
      } while (decomposedIdMetadataUri.length && Number.isNaN(id));

      data['id'] = id;
    }

    if ('member' in data || 'hydra:member' in data) {
      for (const member of data['member'] || data['hydra:member']) {
        resolveIdFromJsonLdMetadata(member);
      }
    }
  }

  return {
    provide: {
      axios: client
    }
  };
});
