import deepmerge from 'deepmerge';
import type { CustomAxiosInstance } from '~/plugins/axios';

export interface FormViolation {
  propertyPath: string;
  message: string;
}

export interface JsonLdResponse {
  '@id': string;
  id?: number;
}

export interface HydraResponse<T> extends JsonLdResponse {
  member: T[];
  view: {
    last: string;
  };

  /** @deprecated */
  'hydra:member': T[];
  'hydra:view': {
    'hydra:last': string;
  };
}

export interface HydraError extends JsonLdResponse {
  description: string;
  violations: FormViolation[];

  /** @deprecated */
  'hydra:description': string;
}

/**
 * Provides utility functions for consuming an API using Nuxt.js Axios instance.
 */
export const useApiConsumer = () => {
  const client = useNuxtApp().$axios as CustomAxiosInstance;

  /**
   * Extracts the page number from a given URL.
   *
   * @param {string} url The URL containing the page number.
   * @return {number} The extracted page number.
   */
  function extractPageNumber(url: string): number {
    const regex = /page=(\d+)/;
    const match = url.match(regex);

    if (match && match[1]) {
      return parseInt(match[1], 10);
    } else {
      throw Error('Unknown pagination number');
    }
  }

  /**
   * Extracts the result from a paginated response.
   *
   * @param {string} url The URL of the API endpoint.
   * @param {object} config The configuration options for the HTTP request (optional).
   */
  async function extractResultFromPaginatedResponse<T>(url: string, config: object = {}): Promise<T[]> {
    const initialResponse = await client.$get<HydraResponse<T>>(
      url,
      deepmerge(config, {
        params: { page: 1, itemsPerPage: 20 }
      })
    );

    const result = initialResponse['member'] || initialResponse['hydra:member'];
    const view = initialResponse['view'] || initialResponse['hydra:view'];

    if (!view || (!('last' in view) && !('hydra:last' in view))) {
      return result;
    }

    const last = view['last'] || view['hydra:last'];
    const totalPages = extractPageNumber(last);

    if (totalPages === 1) {
      return result;
    }

    const requests = [];
    for (let i = 2; i <= totalPages; i++) {
      requests.push(
        client.$get<HydraResponse<T>>(
          url,
          deepmerge(config, {
            params: { page: i, itemsPerPage: 20 }
          })
        )
      );
    }

    const responses = await Promise.all(requests);

    for (const response of responses) {
      result.push(...(response['member'] || response['hydra:member']));
    }

    return result;
  }

  return { ...client, extractResultFromPaginatedResponse };
};
